body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/*
    DEMO STYLE
*/

.container{
    display: flex;
}


p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    background-color: rgba(75, 75, 88, 0.096);
    height: 85px;;
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    width: 250px;
    height: 100vh;
    position: fixed;
    background: black;
    color: #fff;
    transition: all 0.3s;
}

#sidebar.active {
    min-width: 50px;
    max-width: 50px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: left;
}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar .sidebar-header {
    padding-top: 40px;
    background:black;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a i {
    margin-right: 10px;
}


a[data-toggle="collapse"] {
    position: relative;
}


.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

.wrap {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: calc(100% - 50px);
    padding: 40px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    /* background: lightgray; */
    height: inherit;
}

#content.active {
    width: 100%;
}


.navbar-brand.abs
        {
            width: 100%;
            left: 0;
            text-align: center;
        }

.greyiconcolor{
    color: grey;
}

.content_head{
    text-align: center;
    height: calc(100% - 125px);
    position: relative;
}

.textCenter{
    text-align: center;
}
.speedtest{
    text-align: center;
}

.fetch-button {
    min-width: 150px;
    margin-bottom: 30px;
    padding: 15px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    background-color: #343a40;
    border: none;
    color: white;
    transition: all 0.5s;
  }
  
.pre_line {
    white-space: pre-line;
}

.pre_wrap{
    white-space: pre-wrap;
}

#root {
    height: inherit;
}


#nav_content{
    height: 12%;
}

#main_content{
    height: 88%;
}
  
#scroller {
    overflow: auto;
    height: 100%;
}

.margintop20{
    margin-top: 20px;
}
.paddingtop20{
    padding-top: 20px;
}

.light-mode {
background-color: #fff;
color: #333;
transition: background-color 0.3s ease;
}
.dark-mode {
background-color: #1a1919;
color: #fff;
}
.dark-mode-toggle {
	 display: flex;
	 margin: 0 auto;
}
 .dark-mode-toggle > button {
	 font-size: 1.2em;
	 background: none;
	 border: none;
	 color: #ffe600;
	 cursor: pointer;
	 transition: color 0.3s ease;
}
 .dark-mode-toggle > button:last-child {
	 color: #666;
}
 .dark-mode-toggle > button:focus {
	 outline: none;
}
 .toggle-control {
	 position: relative;
	 padding: 0 4px;
	 display: flex;
	 align-items: center;
}
 input[type='checkbox'].dmcheck {
	 width: 40px;
	 height: 10px;
	 background: #555;
	 position: relative;
	 border-radius: 5px;
	 -webkit-appearance: none;
	 -moz-appearance: none;
	 appearance: none;
	 cursor: pointer;
	 vertical-align: 2px;
	 outline: none;
}
 input[type='checkbox'].dmcheck:checked + label {
	 left: 30px;
}
 input[type='checkbox'].dmcheck:focus-visible {
	 outline: solid 2px white;
}
 input[type='checkbox'].dmcheck + label {
	 display: inline-block;
	 width: 18px;
	 height: 18px;
	 border-radius: 50%;
	 transition: all 0.3s ease;
	 cursor: pointer;
	 position: absolute;
	 left: 2px;
	 background: #fff;
	 opacity: 0.9;
     background-color: #f6f6f6;
     top: -4px;
}
 
/* speedtest */



/* speedtest */

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
    #sidebar.active {
        margin-left: 0 !important;
    }
    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    #sidebar ul li a {
        padding: 20px 10px;
    }
    #sidebar ul li a span {
        font-size: 0.85em;
    }
    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }
    #sidebar ul ul a {
        padding: 10px !important;
    }
    #sidebar ul li a i {
        font-size: 1.3em;
    }
    #sidebar {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
    .speedtest img {
        width: 100%;
        height: auto;
      }
}
